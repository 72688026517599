@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

*:focus {
    outline: 0;
    outline: none;
}

::-webkit-scrollbar {
    background: transparent;  /* Optional: just make scrollbar invisible */
    width: 0px;  /* Remove scrollbar space */
    height: 0px; /* Remove scrollbar space for horizontal scrollbars */
}

.MuiDialog-root .MuiDialog-container {
    max-height: 100vh; /* WEBCUS-184 */
}

.details-header-content {
    background: linear-gradient(
        to bottom,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0.12) 30%,
        rgba(0,0,0,0.25) 50%,
        rgba(0,0,0,0.5) 70%,
        rgba(0,0,0,0.75) 90%,
        rgba(0,0,0,0.88) 96%,
        rgba(0,0,0,0.88) 100%
    ); /* Standard syntax (must be last) */
    flex-direction: column;
    height: 100%;
    justify-content: flex-end;
    left: 0;
    pointer-events: none;
    position: absolute;
    top: 0;
    width: 100%;
}

.slick-prev,
.slick-next {
    z-index: 1;
    top: 46%;
}

.slick-prev {
    left: 3%;
}

.slick-next {
    right: 4%;
}

.slick-prev:before,
.slick-next:before {
    color: white !important;
    font-size: 24px;
}

.slick-dots {
    bottom: 0;
    display: flex !important;
    justify-content: space-between !important;
    margin: 0 3% !important;
    opacity: 0.9;
    width: 94% !important;
}

.slick-dots li {
    display: flex;
    justify-content: center;
    width: 100% !important;
}

.slick-dots li button:before {
    color: white !important;
    content: none;
}

.slick-dots li button {
    background-color: #ffffff !important;
    height: 0 !important;
    opacity: 0.5;
    padding: 1px !important;
    width:100% !important;
}

.slick-dots .slick-active > button {
    opacity: 1;
}

.meal-card-slider {
    cursor: pointer;
    height: 100%;
    width: 100%;
}

.meal-card-slider .slick-slider {
    background: linear-gradient(
        to bottom,
        rgba(0,0,0,0) 0%,
        rgba(0,0,0,0.12) 30%,
        rgba(0,0,0,0.25) 50%,
        rgba(0,0,0,0.5) 70%,
        rgba(0,0,0,0.75) 90%,
        rgba(0,0,0,0.88) 96%,
        rgba(0,0,0,0.88) 100%
    );
    height: 100%;
}

.meal-card-slider .slick-slide {
    height: 8rem;
    margin: 8rem 0 0;
    text-shadow: 0 0 8px #000;
}

.slick-slider.full-height {
    flex: 1;
}

.slick-slider.full-height .slick-list,
.slick-slider.full-height .slick-track,
.slick-slider.full-height .slick-slide > div,
.slick-slider.full-height .slick-slide > div > div {
    height: 100%;
}

.slick-slider.full-height .slick-slide > div > div {
    display: flex !important;
}

.feature-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    padding: 4px;
    position: absolute;
    right: 0;
    top: 0;
}

.feature-container > div {
    cursor: pointer;
    display: flex;
    flex-direction: row;
}

.feature {
    align-Items: center;
    background-color: white;
    border-radius: 16px;
    cursor: pointer;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 4px;
    width: 32px;
}

.feature-payment {
    align-Items: center;
    background-color: white;
    border-radius: 0 16px 16px 0;
    display: flex;
    height: 32px;
    justify-content: center;
    margin: 4px;
    padding: 0 10px;
    margin-left: -4px;
}

.paper .MuiDialogContent-root {
    background-color: #f7f7f7;
}

.payment .MuiDialogContent-root{
    padding: 0 16px !important;
}
.white .MuiDialog-paper {
    background-color: #ffffff;
}

.feature > img {
    max-width: 100%;
}

/*
 * https://stackoverflow.com/questions/35583503/input-type-search-no-longer-shows-cancel-button-x-under-ios
 */
input[type="search"]::-webkit-search-cancel-button {
    /* remove default */
    -webkit-appearance: none;

    /* custom styles */
    height: 16px;
    width: 24px;
    display: block;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);

    /* background tweaks for custom icon */
    background-repeat: no-repeat;

    /* icon size */
    background-size: 16px;
    background-position: 4px 0;
}

@font-face {
    font-family: circular-medium;
    src: url("./assets/fonts/circular-medium.ttf");
}

@font-face {
    font-family: Circular-Bold;
    src: url("./assets/fonts/CircularStd-Bold.ttf");
}

@font-face {
    font-family: Roboto-Medium;
    src: url("./assets/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: Roboto-Bold;
    src: url("./assets/fonts/Roboto-Bold.ttf");
}

@font-face {
    font-family: Recoleta-Bold;
    src: url("./assets/fonts/Recoleta-Bold.otf");
}

@keyframes loader-spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
