html {
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    height: auto;
    width: auto;
}

body {
    font-family: sans-serif;
    height: 100%;
    margin: 0 auto;
    max-width: 1800px;
    overflow: hidden;
    padding: 0;
    width: 100%;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}
